import React from 'react'
import { useStateValue } from '../context/StateProvider';
import { MdShoppingBasket } from "react-icons/md";
import {MdClear, MdAdd} from "react-icons/md";
import { motion } from "framer-motion";
import NotFound from "../img/NotFound.svg";
import { useEffect, useState } from 'react';
import {Link} from 'react-router-dom';
// import { useStateValue } from "../context/StateProvider";
// import { actionType } from "../context/reducer";
const CartContainer = () => {
    const [price, setPrice] = useState(0);
    const [{ cartItems }, dispatch] = useStateValue();
    console.log(cartItems);
    useEffect(() => {
        let total = 0;
        cartItems.forEach(element => {
            total += parseInt(element.price);
        });
        setPrice(total)
        // console.log(price);
    }, [])
    return (
    <div>
        {cartItems && cartItems.length > 0 ? (
        cartItems.map((item) => (
          <div
            key={item?.id}
            className="w-275 h-[175px] min-w-[275px] md:w-300 md:min-w-[300px]  bg-cardOverlay rounded-lg py-2 px-4  my-12 backdrop-blur-lg hover:drop-shadow-lg flex flex-col items-center justify-evenly relative"
          >
            <div className="w-full flex items-center justify-between">
              <motion.div
                className="w-40 h-40 -mt-8 drop-shadow-2xl"
                whileHover={{ scale: 1.2 }}
              >
                <img
                  src={item?.imageURL}
                  alt=""
                  className="w-full h-full object-contain"
                />
              </motion.div>
              <motion.div
                whileTap={{ scale: 0.75 }}
                className="w-8 h-8 rounded-full bg-red-600 flex items-center justify-center cursor-pointer hover:shadow-md -mt-8"
                // onClick={() => setItems([...cartItems, item])}
              >
                <MdShoppingBasket className="text-white" />
              </motion.div>
              {/* <MdAdd /> */}
                {/* {item?.qty} */}
              {/* <MdClear /> */}
              {/* <div>Add to cart</div> */}
            </div>

            <div className="w-full flex flex-col items-end justify-end -mt-8">
              <p className="text-textColor font-semibold text-base md:text-lg">
                {item?.title}
              </p>
              <p className="mt-1 text-sm text-gray-500">
                Calories: {item?.calories}
              </p>
              <div className="flex items-center gap-8">
                <p className="text-lg text-headingColor font-semibold">
                  <span className="text-sm text-red-500">₹</span> {item?.price}
                </p>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="w-full flex flex-col items-center justify-center">
          <img src={NotFound} className="h-340" />
          <p className="text-xl text-headingColor font-semibold my-2">
            Items Not Available
          </p>
        </div>
      )}
      <div>Total Cost : {price}</div>
      <Link to="/">
      <button color='brown'> Clear Cart </button>
      </Link>
    </div>
  )
}

export default CartContainer