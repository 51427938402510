import I1 from "../img/Dosa1.png";
import S1 from "../img/sandwich.png";
import R1 from "../img/rolls.png";
import J1 from "../img/watermelon.png";

export const heroData = [{
        id: 1,
        name: "South Indian",
        decp: "Masala Dosa",
        price: "60",
        imageSrc: I1,
    },
    {
        id: 2,
        name: "Juice",
        decp: "Watermelon Juice",
        price: "50",
        imageSrc: J1,
    },
    {
        id: 3,
        name: "Frankie",
        decp: "Panner Roll",
        price: "60",
        imageSrc: R1,
    },
    {
        id: 4,
        name: "Sandwich",
        decp: "Veg Sandwich",
        price: "45",
        imageSrc: S1,
    },
];

export const categories = [{
        id: 10,
        name: "Snacks",
        urlParamName: "snacks",
    },
    {
        id: 1,
        name: "South Indian",
        urlParamName: "southInd",
    },
    {
        id: 2,
        name: "Sandwiches",
        urlParamName: "sandwich",
    },
    {
        id: 3,
        name: "Chinese",
        urlParamName: "chinese",
    },
    {
        id: 4,
        name: "Frankie",
        urlParamName: "frankie",
    },
    {
        id: 5,
        name: "Lunch",
        urlParamName: "lunItems",
    },
    {
        id: 6,
        name: "Chaat",
        urlParamName: "chaatitem",
    },

    {
        id: 7,
        name: "Juices",
        urlParamName: "juice",
    },
    {
        id: 8,
        name: "Beverages",
        urlParamName: "beverage",
    },
    {
        id: 9,
        name: "Pasteries",
        urlParamName: "bakery",
    },
];